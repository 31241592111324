<template>
  <div class="main_box">
    <div class="bot_top">
      <div class="div_back" @click="showLesson">
        <i class="el-icon-arrow-left"></i>
        <span>回主页</span>
      </div>
      <div class="bot_right" @click="handleWatch" v-if="isSelf">
        <img v-if="canWatch" style="margin:-2px 5px 0 0;" src="@/assets/checked.png" alt>
        <img v-else style="margin:-2px 5px 0 0;" src="@/assets/no_checked.png" alt>
        <span>允许别人看</span>
      </div>
    </div>
    <div class="div_content" v-loading="showLoading">
      <p class="title">{{isSelf?'我':'他'}}的粉丝（{{friendCount}}）</p>
      <div class="div_list">
        <div v-for="(item,index) in friendList" class="div_item" :class="friendVip == 2 ?'div_vip':'div_no_vip'">
          <div class="div_item_top" @click="goFriendPage(item)">
            <div class="div_subscript" v-show="item.association===1 && item.follows === 1">互相关注</div>
            <img class="iv_ava" :src="item.avatar"
                 onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'" alt="头像">
            <p class="p_name">{{item.username}}</p>
            <p class="p_msg" v-if="item.tcount>0">本周有{{item.tcount}}消息</p>
            <p class="p_msg"
               v-show="item.follows != 3"
               v-else-if="item.follows === 2 ||　
                       item.association == 1">
              对方关注了你
            </p>
          </div>
          <div class="div_item_bottom" @click="followFriend(item)">
            <span v-if="item.follows === 2" style="color:#ee2e2e;">回关</span>
            <span v-else-if="item.follows === 1" style="color: #999999">取消关注</span>
            <span v-else-if="item.follows === 3" style="color: #999999">进入主页</span>
            <span v-else style="color:#ee2e2e;">+关注</span>
          </div>
        </div>
      </div>
      <el-pagination
              v-show="friendCount>params.limit"
              class="page"
              background
              :total="friendCount"
              layout="prev, pager, next"
              :page-size="params.limit"
              @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 暂无数据 -->
    <div class="noDatasBox" v-show="friendList.length===0 && !showLoading">
      <img src="@/assets/image/kong.png" alt="">
      <p>{{isSelf?'我':'他'}}还没有粉丝哦~</p>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'
  import { getFans,showFans } from '@/api/mine'
  import { follows, cancelfollow } from '@/api/center'

  export default {
    data() {
      return {
        params:{
          uid:this.$route.query.u,
          page:1,
          limit:15,
        },
        friendVip:sessionStorage.getItem('friend_vip'),
        friendCount:0,
        showLoading:true,
        friendList:[],
        isSelf:this.$route.query.isSelf != '0',
        canWatch:false,
        count:0,
        limit:10
      }
    },
    mounted() {
      this.getFriendList()
    },
    methods: {
      getFriendList(){
        this.showLoading = true
        getFans(this.params).then(res=>{
          if (res.errNo === 0){
            this.friendVip = sessionStorage.getItem('friend_vip')
            this.friendCount = res.result.count
            this.friendList = res.result.list
            this.showLoading = false
            if (res.result.show_fans === 1){
              this.canWatch = true
            }else {
              this.canWatch = false
            }
          }
        })
      },
      goFriendPage(item){
        this.$router.push({ path: '/archive', query: { u: item.uid }})
      },
      // 分页
      handleCurrentChange(val) {
        this.params.page = val
        this.getFriendList()
      },
      handleWatch(){
        showFans({status: this.canWatch ? '0':'1'}).then(res=>{
          if (res.errNo === 0){
            this.canWatch = !this.canWatch
          }
        })
      },
      showLesson(){
        this.$router.push({ path: '/archive', query: { u: Cookies.get('uid')}})
      },
      followFriend(item){
        // isFellow 2 回关 已关注 关注
        console.log(item.follows)
        if (item.follows == 3 ){
          this.goFriendPage(item)
        }else if (item.follows != 1 ) {

          follows({'uid':item.uid}).then(res => {
            const errNo = res.errNo
            if (errNo === 0) {
              if (item.follows === 2){
                item.association = 1
              }
              item.follows = 1
            } else {
              const msg = res.msg
              this.$message.error(msg)
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          cancelfollow({'uid':item.uid}).then(res => {
            const errNo = res.errNo
            if (errNo === 0) {
              if (item.association === 1){
                item.association =0
                item.follows = 2
              }else {
                item.follows = 0
              }
            } else {
              const msg = res.msg
              this.$message.error(msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .main_box{
    min-height: 200px;
    .bot_top{
      height: 56px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .div_back{
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: start;
        font-size: 16px;
        font-weight: 600;
      }
      .bot_right{
        display: flex;
        cursor: pointer;
        align-items: center;
        img{
          width: 20px;
        }
      }
    }
    .div_content{
      padding: 0 40px 0 52px;
      .title{
        margin: 16px 0;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
      }
      .div_list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .div_vip{
          border: 1px solid #BEB5AC;
          background: #BEB5AC;
        }
        .div_no_vip{
          border: 1px solid #807979;
          background: #807979;
        }
        .div_item{
          width: 152px;
          cursor: pointer;
          height: 180px;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          flex-shrink: 0;
          margin-bottom: 15px;
          margin-right: 15px;
          .div_item_top{
            height: 139px;
            position: relative;
            text-align: center;
            .div_subscript{
              width: 58px;
              height: 18px;
              position: absolute;
              font-size: 12px;
              background: white;
              border-radius: 3px;
              right: 0px;
              margin-top: 2px;
              margin-right: 2px;
            }
            .iv_ava{
              width: 54px;
              border-radius: 50%;
              height: 54px;
              margin-top: 25px;
            }
            .p_name{
              font-size: 14px;
              margin-bottom: 3px;
              margin-top: 6px;
              color: white;
            }
            .p_msg{
              font-size: 12px;
              color: white;
            }
          }
          .div_item_bottom{
            line-height: 39px;
            cursor: pointer;
            height: 39px;
            background: white;
          }
        }

      }
    }
  }
</style>
